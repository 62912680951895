import Typography from '@material-ui/core/Typography'
import React from 'react'

import useStyles from './useStyles'

interface Props {
  message: string
}

const ProcessingFailed = (props: Props) => {
  const { message } = props
  const classes = useStyles()

  return (
    <div className={classes.failed}>
      <Typography variant="h4" component="span" gutterBottom>
        {message}
      </Typography>
    </div>
  )
}

export default ProcessingFailed
