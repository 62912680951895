import React from 'react'

import { Video } from '../../types'
import useStyles from './useStyles'

interface Props {
  video: Video
}

const VideoPlayer = (props: Props) => {
  const { video } = props
  const classes = useStyles()

  return (
    <video controls className={classes.video} poster={video.thumbnail} muted>
      <source src={video.url} type="video/mp4" />
    </video>
  )
}

export default VideoPlayer
