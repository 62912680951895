/* eslint-disable camelcase */
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { InputData } from '../../types'
import CompareWith from '../CompareWith/CompareWith'
import Header from '../Header/Header'
import PlayerDetails from '../PlayerDetails/PlayerDetails'
import ReportDetails from '../ReportDetails/ReportDetails'
import StyledDivider from '../StyledDivider/StyledDivider'
import useStyles from './useStyles'

interface Props {
  data: InputData
  cycles: number | null
}

const ReportHeader = (props: Props) => {
  const { data, cycles } = props
  const { date, hst, player, training_center } = data
  const [t] = useTranslation()
  const classes = useStyles()

  return (
    <Grid component="header" container item md={12} className={classes.header}>
      <Header title="report" />

      <Grid container item xs={12} direction="column">
        <Typography variant="h2" component="h2" align="center">
          {player.name}
        </Typography>
        <Typography variant="h6" component="div" gutterBottom align="center">
          {training_center}
        </Typography>
      </Grid>

      <ReportDetails hst={hst} date={date} training_center={training_center} cycles={cycles} />

      <StyledDivider />

      <Grid container item xs={12} className={classes.padding}>
        <CompareWith />
      </Grid>

      <StyledDivider />

      <Grid container item xs={12}>
        <Typography variant="h4" component="h2" gutterBottom>
          {t('PlayerDetailsTitle')}
        </Typography>
      </Grid>

      <PlayerDetails player={player} training_center={training_center} />

      <StyledDivider />
    </Grid>
  )
}

export default ReportHeader
