import { Language } from '../types'
import { DEFAULT_LANG } from './consts'

export const dateTimeOptions = { year: 'numeric', month: 'short', day: 'numeric' }

export const getLocaleString = (date: Date, language: Language, additionalOptions?: Object) =>
  date.toLocaleString(language !== 'test' ? language : DEFAULT_LANG, {
    ...dateTimeOptions,
    ...additionalOptions,
  })
