import Divider from '@material-ui/core/Divider'
import React from 'react'

import useStyles from './useStyles'

const StyledDivider = () => {
  const classes = useStyles()
  return <Divider variant="middle" className={classes.divider} />
}

export default StyledDivider
