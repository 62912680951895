import {
  ApplicationState,
  ApplicationTypes,
  CHANGE_NOTE,
  CLEAR_MESSAGE,
  DOWNLOAD_REPORT_ERRORED,
  DOWNLOAD_REPORT_FINISHED,
  DOWNLOAD_REPORT_STARTED,
  LOAD_REPORT_ERRORED,
  LOAD_REPORT_FINISHED,
  LOAD_REPORT_STARTED,
  LOAD_REPORTS_FOR_COMPARISON_ERRORED,
  LOAD_REPORTS_FOR_COMPARISON_FINISHED,
  LOAD_REPORTS_FOR_COMPARISON_STARTED,
  LOAD_TRANSLATION_ERRORED,
  LOAD_TRANSLATION_FINISHED,
  LOAD_TRANSLATION_STARTED,
  SAVE_NOTE_ERRORED,
  SAVE_NOTE_FINISHED,
  SAVE_NOTE_STARTED,
  SAVE_NOTES_ERRORED,
  SAVE_NOTES_FINISHED,
  SAVE_NOTES_STARTED,
  SEND_EMAIL_ERRORED,
  SEND_EMAIL_FINISHED,
  SEND_EMAIL_STARTED,
  SET_MESSAGE,
} from './types'

export const initialState: ApplicationState = {
  data: null,
  blob: null,
  isLoadingReport: false,
  isDownloadingReport: false,
  isSendingEmail: false,
  loadReportError: null,
  errorMessage: '',
  message: { text: '', variant: 'error' },
  newNotes: [],
  comparison: {
    reportA: null,
    reportB: null,
  },
  isLoadingComparison: false,
  isLoadingLanguage: false,
  language: null,
  translation: {},
}

const applicationReducer = (state = initialState, action: ApplicationTypes): ApplicationState => {
  switch (action.type) {
    case LOAD_REPORT_STARTED:
      return {
        ...state,
        data: null,
        isLoadingReport: true,
        loadReportError: null,
      }
    case LOAD_REPORT_FINISHED:
      return {
        ...state,
        data: action.payload.data,
        isLoadingReport: false,
        language: action.payload.data.lang,
        translation: action.payload.data.dict,
      }
    case LOAD_REPORT_ERRORED:
      return {
        ...state,
        isLoadingReport: false,
        loadReportError: 'Error',
      }
    case DOWNLOAD_REPORT_STARTED:
      return {
        ...state,
        blob: null,
        isDownloadingReport: true,
        errorMessage: '',
      }
    case DOWNLOAD_REPORT_FINISHED:
      return {
        ...state,
        isDownloadingReport: false,
        blob: action.payload.blob,
      }
    case DOWNLOAD_REPORT_ERRORED:
      return {
        ...state,
        isDownloadingReport: false,
        errorMessage: 'Error',
        message: {
          text: 'NotifyDownloadError',
          variant: 'error',
        },
      }
    case SEND_EMAIL_STARTED:
      return {
        ...state,
        isSendingEmail: true,
        errorMessage: '',
      }
    case SEND_EMAIL_FINISHED:
      return {
        ...state,
        isSendingEmail: false,
        message: { text: 'NotifySendToEmailSuccess', variant: 'success' },
      }
    case SEND_EMAIL_ERRORED:
      return {
        ...state,
        isSendingEmail: false,
        message: { text: 'NotifySendToEmailError', variant: 'error' },
      }
    case SAVE_NOTES_STARTED:
      return {
        ...state,
      }
    case SAVE_NOTES_ERRORED:
      return {
        ...state,
      }
    case SAVE_NOTES_FINISHED:
      return {
        ...state,
        data: action.payload.data,
        newNotes: [],
      }
    case SAVE_NOTE_STARTED:
      return {
        ...state,
      }
    case SAVE_NOTE_ERRORED:
      return {
        ...state,
      }
    case SAVE_NOTE_FINISHED: {
      const start = state.newNotes.findIndex(
        (element) => element.a_idx === action.payload.a && element.r_idx === action.payload.r,
      )
      if (start >= 0) {
        state.newNotes.splice(start, 1)
      }
      return {
        ...state,
        data: action.payload.data,
        newNotes: state.newNotes,
      }
    }
    case CHANGE_NOTE: {
      const { note } = action.payload
      const notes = state.newNotes
      const index = notes.findIndex((element) => element.a_idx === note.a_idx && element.r_idx === note.r_idx)
      if (index >= 0) {
        notes[index] = note
      } else {
        notes.push(note)
      }
      return {
        ...state,
        newNotes: notes,
      }
    }
    case SET_MESSAGE:
      return {
        ...state,
        message: action.payload.message,
      }
    case CLEAR_MESSAGE:
      return {
        ...state,
        message: { text: '', variant: 'success' },
      }
    case LOAD_REPORTS_FOR_COMPARISON_STARTED:
      return {
        ...state,
        isLoadingComparison: true,
        comparison: { reportA: null, reportB: null },
      }
    case LOAD_REPORTS_FOR_COMPARISON_ERRORED:
      return {
        ...state,
        isLoadingComparison: false,
        loadReportError: 'Error',
      }
    case LOAD_REPORTS_FOR_COMPARISON_FINISHED:
      return {
        ...state,
        isLoadingComparison: false,
        comparison: action.payload.comparison,
        language: action.payload.comparison.reportA.lang,
        translation: action.payload.comparison.reportA.dict,
      }
    case LOAD_TRANSLATION_STARTED:
      return {
        ...state,
        isLoadingLanguage: true,
      }
    case LOAD_TRANSLATION_FINISHED:
      return {
        ...state,
        isLoadingLanguage: false,
        language: action.payload.language,
        translation: action.payload.translation,
      }
    case LOAD_TRANSLATION_ERRORED:
      return {
        ...state,
        isLoadingLanguage: false,
      }
    default:
      return state
  }
}

export default applicationReducer
