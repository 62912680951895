import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'

const themeWithoutResponsiveFontSizes = createMuiTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Source Sans Pro"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: {
      light: '#006699',
      main: '#006699',
      dark: '#006699',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff4081',
      main: '#ff4081',
      dark: '#ff4081',
      contrastText: '#fff',
    },
  },
})

const theme = responsiveFontSizes(themeWithoutResponsiveFontSizes)

// eslint-disable-next-line import/prefer-default-export
export { theme }
