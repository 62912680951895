import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Result as ResultType } from '../../types'
import Note from './Note'
import useStyles from './useStyles'

interface Props {
  result: ResultType
  a: number
  r: number
  editable: boolean
}

const Result = (props: Props) => {
  const { result, a, r, editable } = props
  const classes = useStyles()
  const [t] = useTranslation()

  return (
    <Grid container component="article" className={classes.result}>
      <Grid item sm={12} component="header">
        <Typography variant="h5" gutterBottom>
          {r + 1}. {result.title}
        </Typography>
      </Grid>
      <Grid container item sm={12} spacing={2}>
        <Grid item md={8} xs={12}>
          <img src={result.image} alt={result.title} className={classes.resultImage} />
        </Grid>
        <Grid item md={4} xs={12}>
          {result.value !== null && (
            <>
              <Typography variant="body1">{result.metric}:</Typography>
              <Typography variant="h6" gutterBottom component="p">
                {result.value}
                {result.unit}
                {result.deviation !== null && ` ± ${result.deviation}${result.unit ? result.unit : ''}`}
              </Typography>
            </>
          )}
          <Typography variant="body1">{t('AutoGeneratedComment')}:</Typography>
          <Typography variant="body1" gutterBottom className={classes.i}>
            {result.comment_1} {result.comment_2}
          </Typography>
          <Note result={result} a={a} r={r} editable={editable} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Result
