import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Result as ResultType } from '../../types'
import useStyles from './useStyles'

interface Props {
  result: ResultType
  isBetter: boolean | null
}

const ComparsionResult = (props: Props) => {
  const { result, isBetter } = props
  const classes = useStyles()
  const [t] = useTranslation()

  return (
    <Grid container item sm={12} md={6} className={classes.result}>
      <Grid item sm={12} container justify="center" className={classes.imageContainer}>
        <img src={result.image} alt={result.title} className={classes.resultImage} />
      </Grid>
      <Grid item xs={12}>
        {result.value !== null && (
          <>
            <Typography variant="body1">{result.metric}:</Typography>
            <Typography
              variant="h6"
              gutterBottom
              component="p"
              // eslint-disable-next-line no-nested-ternary
              className={isBetter ? classes.ok : isBetter === false ? classes.nok : ''}
            >
              {result.value}
              {result.unit}
              {result.deviation !== null && ` ± ${result.deviation}${result.unit ? result.unit : ''}`}
            </Typography>
          </>
        )}
        <Typography variant="body1">{t('AutoGeneratedComment')}:</Typography>
        <Typography variant="body1" gutterBottom className={classes.i}>
          {result.comment_1} {result.comment_2}
        </Typography>
        <Typography variant="body1">{t('Notes')}:</Typography>
        <Typography variant="body1" gutterBottom className={classes.i}>
          {result.notes}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default ComparsionResult
