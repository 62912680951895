import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import TextField from '@material-ui/core/TextField'
// eslint-disable-next-line import/no-unresolved
import { TransitionProps } from '@material-ui/core/transitions'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'

import { sendReportToEmail } from '../../store/application/actions'
import { GlobalState } from '../../store/configureStore'
import { LocationState, ParamTypes } from '../../types'
import { useQuery } from '../../utils/useQuery'

const Transition = React.forwardRef(function Transition(
  // eslint-disable-next-line react/require-default-props
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Slide direction="up" ref={ref} {...props} />
})

interface Props {
  open: boolean
  onClose: () => void
}

const EmailDialog = (props: Props) => {
  const { onClose, open } = props
  const location = useLocation<LocationState>()
  const dispatch = useDispatch()
  const [t] = useTranslation()
  const { id } = useParams<ParamTypes>()
  const { edit } = useQuery(location)

  const [email, setEmail] = useState<string>('')

  const language = useSelector((state: GlobalState) => state.application.language)

  useEffect(() => {
    if (open) {
      setEmail('')
    }
  }, [open])

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" TransitionComponent={Transition}>
      <DialogTitle id="form-dialog-title">{t('SendToEmailTitle')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('EnterEmailAddress', 'Enter the email to which you want to send this report as a pdf.')}
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Email"
          type="email"
          fullWidth
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('Back')}
        </Button>
        <Button
          onClick={() => {
            dispatch(sendReportToEmail(id, email, edit || '', language))
            onClose()
          }}
          color="primary"
        >
          {t('Send')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EmailDialog
