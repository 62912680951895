import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import React, { FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import useStyles from './useStyles'

interface GoToReportFormProps {
  value: string
}

const GoToReportForm = (props: GoToReportFormProps) => {
  const { value } = props
  const classes = useStyles()
  const history = useHistory()
  const [t] = useTranslation()

  const [id, setId] = useState<string>(value)
  const [error, setError] = useState<string>('')

  useEffect(() => {
    if (id === '' || id.match(/^[a-f\d]{8}-[a-f\d]{4}-4[a-f\d]{3}-[89ab][a-f\d]{3}-[a-f\d]{12}$/i)) {
      setError('')
    } else {
      setError(t('InvalidID'))
    }
  }, [id, t])

  const openReport = () => {
    if (error.length === 0 && id !== '') {
      history.push(`/report/${id}`)
    }
  }

  return (
    <Grid
      container
      item
      direction="column"
      alignItems="center"
      component="form"
      noValidate
      onSubmit={(e: FormEvent) => {
        e.preventDefault()
        openReport()
      }}
    >
      <TextField
        error={error.length > 0}
        variant="outlined"
        margin="normal"
        fullWidth
        id="uuid"
        label={t('ID')}
        name="id"
        autoFocus
        value={id}
        onChange={(event) => setId(event.target.value)}
        helperText={error || ' '}
        inputProps={{ style: { textAlign: 'center' } }}
        className={classes.inputField}
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={classes.submit}
        disabled={error.length > 0 || id === ''}
      >
        {t('Open')}
      </Button>
    </Grid>
  )
}

export default GoToReportForm
