import { CircularProgress } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded'
import MailIcon from '@material-ui/icons/Mail'
import SaveIcon from '@material-ui/icons/Save'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'

import { downloadReport, saveNotes, setMessage } from '../../store/application/actions'
import { GlobalState } from '../../store/configureStore'
import { LocationState, ParamTypes } from '../../types'
import { generateReportName } from '../../utils/generateReportName'
import { getPdfFromResponse } from '../../utils/responses'
import { useQuery } from '../../utils/useQuery'
import EmailDialog from '../EmailDialog/EmailDialog'
import useStyles from './useStyles'

const ReportFooter = () => {
  const classes = useStyles()
  const location = useLocation<LocationState>()
  const dispatch = useDispatch()
  const [t] = useTranslation()
  const { id } = useParams<ParamTypes>()
  const { edit, lang: queryLang } = useQuery(location)

  const [open, setOpen] = useState<boolean>(false)

  const message = useSelector((state: GlobalState) => state.application.message)
  const isEditable = useSelector((state: GlobalState) => state.application.data?.is_editable)
  const name = useSelector((state: GlobalState) => state.application.data?.input_data.player.name)
  const date = useSelector((state: GlobalState) => state.application.data?.input_data.date)
  const newNotesLength = useSelector((state: GlobalState) => state.application.newNotes.length)
  const isDownloadingReport = useSelector((state: GlobalState) => state.application.isDownloadingReport)
  const blob = useSelector((state: GlobalState) => state.application.blob)
  const language = useSelector((state: GlobalState) => state.application.language)

  useEffect(() => {
    if (message.variant === 'success') {
      setOpen(false)
    }
  }, [message])

  useEffect(() => {
    if (blob && name && date) {
      getPdfFromResponse(blob, generateReportName(name, date))
    }
  }, [blob, date, name])

  return (
    <Grid container item md={12} component="footer">
      <Divider variant="middle" className={classes.divider} />

      <Grid container>
        {isEditable && (
          <Grid container item md={12}>
            <TextField
              id="shareable-link"
              label={t('ShareableLink')}
              value={`${window.location.host}${location.pathname}${queryLang ? `?lang=${queryLang}` : ''}`}
              className={classes.copyReport}
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              size="small"
              fullWidth
              margin="normal"
              onClick={() =>
                navigator.clipboard
                  .writeText(`${window.location.host}${location.pathname}`)
                  .then(() => dispatch(setMessage({ text: 'NotifyShareableLinkWasCopied', variant: 'success' })))
              }
            />
          </Grid>
        )}
        <Grid container item md={12}>
          <div className={classes.downloadWrapper}>
            <Button
              type="button"
              variant="contained"
              color="primary"
              startIcon={<GetAppRoundedIcon />}
              disabled={isDownloadingReport}
              className={classes.footerButton}
              onClick={() => {
                dispatch(downloadReport(id, language))
              }}
            >
              {t('DownloadPDF')}
            </Button>
            {isDownloadingReport && <CircularProgress className={classes.progress} color="primary" size={20} />}
          </div>
          {isEditable && (
            <>
              <Button
                type="button"
                variant="contained"
                color="secondary"
                startIcon={<MailIcon />}
                onClick={() => setOpen(true)}
                className={classes.footerButton}
              >
                {t('SendToEmail')}
              </Button>

              <Button
                type="button"
                variant="contained"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={() => dispatch(saveNotes(id, edit || ''))}
                className={classes.footerButton}
                disabled={newNotesLength === 0}
              >
                {t('SaveAll')}
              </Button>
            </>
          )}
        </Grid>
      </Grid>
      <EmailDialog open={open} onClose={() => setOpen(false)} />
    </Grid>
  )
}

export default ReportFooter
