import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import React from 'react'

import useStyles from './useStyles'

interface Props {
  label: string
  value: string | number | null
}

const DefinitionItem = (props: Props) => {
  const { label, value } = props
  const classes = useStyles()

  return (
    <Grid item container wrap="nowrap" className={classes.definition}>
      <Typography component="dt" variant="body2" noWrap className={classes.dt}>
        {label}:
      </Typography>
      <Typography component="dd" variant="subtitle2" noWrap className={classes.dd}>
        {value !== null ? value : '–'}
      </Typography>
    </Grid>
  )
}

export default DefinitionItem
