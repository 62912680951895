import { Data, Language, Message, Note } from '../../types'

export interface ApplicationState {
  data: Data | null
  blob: Blob | null
  isLoadingReport: boolean
  isDownloadingReport: boolean
  isSendingEmail: boolean
  loadReportError: string | null
  errorMessage: string
  message: Message
  newNotes: Note[]
  comparison: {
    reportA: Data | null
    reportB: Data | null
  }
  isLoadingComparison: boolean
  isLoadingLanguage: boolean
  language: Language | null
  translation: Object
}

export const LOAD_REPORT_STARTED = 'LOAD_REPORT_STARTED'
export const LOAD_REPORT_FINISHED = 'LOAD_REPORT_FINISHED'
export const LOAD_REPORT_ERRORED = 'LOAD_REPORT_ERRORED'
export const DOWNLOAD_REPORT_STARTED = 'DOWNLOAD_REPORT_STARTED'
export const DOWNLOAD_REPORT_FINISHED = 'DOWNLOAD_REPORT_FINISHED'
export const DOWNLOAD_REPORT_ERRORED = 'DOWNLOAD_REPORT_ERRORED'
export const SEND_EMAIL_STARTED = 'SEND_EMAIL_STARTED'
export const SEND_EMAIL_FINISHED = 'SEND_EMAIL_FINISHED'
export const SEND_EMAIL_ERRORED = 'SEND_EMAIL_ERRORED'
export const SAVE_NOTES_STARTED = 'SAVE_NOTES_STARTED'
export const SAVE_NOTES_FINISHED = 'SAVE_NOTES_FINISHED'
export const SAVE_NOTES_ERRORED = 'SAVE_NOTES_ERRORED'
export const SAVE_NOTE_STARTED = 'SAVE_NOTE_STARTED'
export const SAVE_NOTE_FINISHED = 'SAVE_NOTE_FINISHED'
export const SAVE_NOTE_ERRORED = 'SAVE_NOTE_ERRORED'
export const LOAD_REPORTS_FOR_COMPARISON_ERRORED = 'LOAD_REPORTS_FOR_COMPARISON_ERRORED'
export const LOAD_REPORTS_FOR_COMPARISON_FINISHED = 'LOAD_REPORTS_FOR_COMPARISON_FINISHED'
export const LOAD_REPORTS_FOR_COMPARISON_STARTED = 'LOAD_REPORTS_FOR_COMPARISON_STARTED'
export const CHANGE_NOTE = 'CHANGE_NOTE'
export const SET_MESSAGE = 'SET_MESSAGE'
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE'
export const LOAD_TRANSLATION_STARTED = 'LOAD_TRANSLATION_STARTED'
export const LOAD_TRANSLATION_FINISHED = 'LOAD_TRANSLATION_FINISHED'
export const LOAD_TRANSLATION_ERRORED = 'LOAD_TRANSLATION_ERRORED'

interface LoadReportStarted {
  type: typeof LOAD_REPORT_STARTED
}

interface LoadReportFinished {
  type: typeof LOAD_REPORT_FINISHED
  payload: {
    data: Data
  }
}

interface LoadReportErrored {
  type: typeof LOAD_REPORT_ERRORED
}

interface DownloadReportStarted {
  type: typeof DOWNLOAD_REPORT_STARTED
}

interface DownloadReportFinished {
  type: typeof DOWNLOAD_REPORT_FINISHED
  payload: {
    blob: Blob
  }
}

interface DownloadReportErrored {
  type: typeof DOWNLOAD_REPORT_ERRORED
}

interface SendEmailStarted {
  type: typeof SEND_EMAIL_STARTED
}

interface SendEmailFinished {
  type: typeof SEND_EMAIL_FINISHED
}

interface SendEmailErrored {
  type: typeof SEND_EMAIL_ERRORED
}

interface SaveNotesStarted {
  type: typeof SAVE_NOTES_STARTED
}

interface SaveNotesFinished {
  type: typeof SAVE_NOTES_FINISHED
  payload: {
    data: Data
  }
}

interface SaveNotesErrored {
  type: typeof SAVE_NOTES_ERRORED
}

interface SaveNoteStarted {
  type: typeof SAVE_NOTE_STARTED
}

interface SaveNoteFinished {
  type: typeof SAVE_NOTE_FINISHED
  payload: {
    a: number
    r: number
    data: Data
  }
}

interface SaveNoteErrored {
  type: typeof SAVE_NOTE_ERRORED
}

interface ChangeNote {
  type: typeof CHANGE_NOTE
  payload: {
    note: Note
  }
}

interface SetMessage {
  type: typeof SET_MESSAGE
  payload: {
    message: Message
  }
}

interface ClearMessage {
  type: typeof CLEAR_MESSAGE
}

interface LoadReportsStarted {
  type: typeof LOAD_REPORTS_FOR_COMPARISON_STARTED
}

interface LoadReportsFinished {
  type: typeof LOAD_REPORTS_FOR_COMPARISON_FINISHED
  payload: {
    comparison: {
      reportA: Data
      reportB: Data
    }
  }
}

interface LoadReportsErrored {
  type: typeof LOAD_REPORTS_FOR_COMPARISON_ERRORED
}

interface LoadTranslationStarted {
  type: typeof LOAD_TRANSLATION_STARTED
}

interface LoadTranslationFinished {
  type: typeof LOAD_TRANSLATION_FINISHED
  payload: {
    language: Language
    translation: Object
  }
}

interface LoadTranslationErrored {
  type: typeof LOAD_TRANSLATION_ERRORED
}

export type ApplicationTypes =
  | LoadReportStarted
  | LoadReportFinished
  | LoadReportErrored
  | DownloadReportStarted
  | DownloadReportFinished
  | DownloadReportErrored
  | SendEmailStarted
  | SendEmailFinished
  | SendEmailErrored
  | SaveNotesStarted
  | SaveNotesFinished
  | SaveNotesErrored
  | SaveNoteStarted
  | SaveNoteFinished
  | SaveNoteErrored
  | ChangeNote
  | SetMessage
  | ClearMessage
  | LoadReportsStarted
  | LoadReportsFinished
  | LoadReportsErrored
  | LoadTranslationStarted
  | LoadTranslationFinished
  | LoadTranslationErrored
