import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((t: Theme) => ({
  failed: {
    marginBottom: t.spacing(6),
    color: t.palette.error.main,
  },
  mail: {
    color: t.palette.error.main,
    fontWeight: 700,
  },
}))

export default useStyles
