import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import frontend from './locales/en-US/frontend.json'
import { DEFAULT_LANG } from './utils/consts'

const resources = {
  'en-US': {
    frontend,
  },
}

i18n.use(initReactI18next).init({
  resources,
  lng: DEFAULT_LANG,
  fallbackLng: DEFAULT_LANG,
  ns: ['frontend'],
  defaultNS: 'frontend',
  debug: false,
  keySeparator: false,
  pluralSeparator: ' | ',
  contextSeparator: ' || ',
})

export default i18n
