import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((t: Theme) => ({
  header: {
    marginBottom: t.spacing(4),
  },
  padding: {
    paddingLeft: t.spacing(2),
    paddingRight: t.spacing(2),
  },
}))

export default useStyles
