/* eslint-disable camelcase */
import { Button } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Hst } from '../../types'
import ReportDetails from '../ReportDetails/ReportDetails'
import useStyles from './useStyles'

interface Props {
  id: string
  hst: Hst
  date: string
  training_center: string
  cycles: number | null
}

const ComparisonReportDetails = (props: Props) => {
  const { hst, date, id, training_center, cycles } = props
  const [t] = useTranslation()
  const classes = useStyles()

  return (
    <>
      <ReportDetails hst={hst} date={date} training_center={training_center} cycles={cycles} />
      <Grid container item xs={12} justify="center">
        <Grid container item md={10}>
          <Button
            href={`/report/${id}`}
            target="_blank"
            variant="contained"
            color="primary"
            fullWidth
            size="small"
            className={classes.reportLink}
          >
            {t('OpenOriginalReport')}
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default ComparisonReportDetails
