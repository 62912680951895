// ISO 3166-1 alpha-2
// No support for IE 11
// eslint-disable-next-line import/prefer-default-export
export const countryToFlag = (isoCode: string) => {
  if (typeof String.fromCodePoint === 'undefined') {
    return isoCode
  }
  if (isoCode === 'TEST') {
    // return String.fromCodePoint(127987) // White Flag
    // return String.fromCodePoint(127988) // Black Flag
    return (
      String.fromCodePoint(127988) +
      String.fromCodePoint(8205) +
      String.fromCodePoint(9760) +
      String.fromCodePoint(65039)
    ) // Pirate Flag
  }
  return isoCode.toUpperCase().replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
}
