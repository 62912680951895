import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((t: Theme) => ({
  logo: {
    width: '100px',
    cursor: 'pointer',
  },
  header: {
    marginBottom: t.spacing(4),
  },
  title: {
    marginTop: t.spacing(2),
  },
  heading: {
    order: 2,
    [t.breakpoints.down('sm')]: {
      order: 3,
    },
  },
  languagePicker: {
    order: 3,
    [t.breakpoints.down('sm')]: {
      order: 2,
    },
  },
}))

export default useStyles
