import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import React from 'react'

import useStyles from './useStyles'

const Loading = () => {
  const classes = useStyles()

  return (
    <Box display="flex" flexDirection="column" className={classes.notFound}>
      <CircularProgress size={60} />
    </Box>
  )
}

export default Loading
