/* eslint-disable import/prefer-default-export */
const isLowerBetter = [
  [true, true, null, null, null, null],
  [false, true, false, false],
]

export const isBetter = (a: number | null, b: number | null, viewIdx: number, i: number): boolean | null => {
  if (isLowerBetter[viewIdx][i] === null || a === null || b === null) {
    return null
  }
  if (a === b) {
    return true
  }
  return isLowerBetter[viewIdx][i] ? a < b : a > b
}
