import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((t: Theme) => ({
  footer: {
    backgroundColor: t.palette.primary.main,
    padding: t.spacing(2),
    color: t.palette.common.white,
    marginTop: 'auto',
    width: '100%',
  },
  footerLink: {
    color: t.palette.common.white,
    textDecoration: 'none',
    fontWeight: 'bold',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))

export default useStyles
