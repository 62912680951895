/* eslint-disable camelcase */
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import React, { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import Footer from '../../components/Footer/Footer'
import Loading from '../../components/Loading/Loading'
import ProcessingFailed from '../../components/ProcessingFailed/ProcessingFailed'
import ReportFooter from '../../components/ReportFooter/ReportFooter'
import ReportHeader from '../../components/ReportHeader/ReportHeader'
import VideoPlayer from '../../components/VideoPlayer/VideoPlayer'
import View from '../../components/View/View'
import WrongReport from '../../components/WrongReport/WrongReport'
import { getReport } from '../../store/application/actions'
import { GlobalState } from '../../store/configureStore'
import { LocationState, ParamTypes } from '../../types'
import { useQuery } from '../../utils/useQuery'
import MainPage from '../MainPage/MainPage'
import useStyles from './useStyles'

const Report = () => {
  const classes = useStyles()
  const location = useLocation<LocationState>()
  const history = useHistory()
  const dispatch = useDispatch()
  const [t] = useTranslation()
  const [cookies] = useCookies()
  const { id } = useParams<ParamTypes>()
  const { edit, lang: queryLang } = useQuery(location)

  const data = useSelector((state: GlobalState) => state.application.data)
  const loadReportError = useSelector((state: GlobalState) => state.application.loadReportError)
  const language = useSelector((state: GlobalState) => state.application.language)

  const selectedLang = cookies.lang || queryLang

  useEffect(() => {
    dispatch(getReport(id, edit, selectedLang))
  }, [dispatch, id, edit, selectedLang])

  useEffect(() => {
    if (selectedLang === language) {
      history.push({
        search: `${edit ? `edit=${edit}` : ''}${edit && language ? '&' : ''}${language ? `lang=${language}` : ''}`,
      })
    }
  }, [edit, history, language, selectedLang])

  if (loadReportError !== null) {
    return (
      <MainPage title={t('ReportNotFound')}>
        <WrongReport />
      </MainPage>
    )
  }

  if (data === null) {
    return <Loading />
  }

  const { report, is_editable, input_data, status } = data

  return (
    <>
      <Helmet>
        <title>{`SA | ${t('Report')} - ${input_data.player.name}`}</title>
      </Helmet>
      <main className={classes.root}>
        <Container maxWidth="lg" component={Paper} className={classes.paper}>
          <ReportHeader data={input_data} cycles={report?.key_positions?.count || null} />
          {report && status === 'success' ? (
            <>
              <section>
                <Grid container item md={12}>
                  <Grid container item xs={12}>
                    <Typography variant="h4" component="h2" gutterBottom>
                      {t('VideoReport', 'Video report of the performance')}
                    </Typography>
                  </Grid>
                  <Grid container item xs={12}>
                    <VideoPlayer video={report.video} />
                  </Grid>
                </Grid>
                {report.analyses.map((analyse, i) => (
                  <Grid container item md={12} className={classes.view} key={analyse.header}>
                    <View view={analyse} editable={is_editable} index={i} />
                  </Grid>
                ))}
              </section>
              <ReportFooter />
            </>
          ) : (
            <ProcessingFailed message={t('ReportProcessingFailed', 'Report has failed during the processing')} />
          )}
        </Container>
      </main>
      <Footer />
    </>
  )
}

export default Report
