import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((t: Theme) => ({
  inputField: {
    width: '360px',
    [t.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  submit: {
    margin: t.spacing(1, 0, 3),
    width: '200px',
    [t.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}))

export default useStyles
