import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { useTranslation } from 'react-i18next'

import GoToReportForm from '../../components/GoToReportForm/GoToReportForm'
import { SKATING_ANALYSIS } from '../../utils/consts'

const HomePage = () => {
  const [t] = useTranslation()

  return (
    <Grid container direction="column" alignItems="center">
      <Typography component="h1" variant="h4" align="center" color="textPrimary" gutterBottom>
        {t('WelcomeMessage', { SKATING_ANALYSIS })}
      </Typography>
      <Typography variant="h5" align="center" color="textPrimary" gutterBottom>
        {t('EnterReportID')}
      </Typography>
      <GoToReportForm value="" />
    </Grid>
  )
}

export default HomePage
