import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import React, { ReactElement, useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { Helmet } from 'react-helmet'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import Footer from '../../components/Footer/Footer'
import LanguagePicker from '../../components/LanguagePicker/LanguagePicker'
import { getTranslation } from '../../store/application/actions'
import { DEFAULT_LANG } from '../../utils/consts'
import { ReactComponent as Logo } from '../../utils/Square44x44Logo.svg'
import useStyles from './useStyles'

interface Props {
  title: string
  children: ReactElement
}

const MainPage = (props: Props) => {
  const { title, children } = props

  const classes = useStyles()
  const dispatch = useDispatch()
  const [cookies] = useCookies()

  useEffect(() => {
    dispatch(getTranslation(cookies.lang || DEFAULT_LANG))
  }, [cookies.lang, dispatch])

  return (
    <>
      <Helmet>
        <title>{`SA | ${title}`}</title>
      </Helmet>
      <div className={classes.mainPage}>
        <Container maxWidth="lg" className={classes.container}>
          <Grid container direction="column" alignItems="center" component="header" className={classes.header}>
            <Grid container item xs={12} justify="flex-end">
              <Grid container item md={2} sm={4} xs={6}>
                <LanguagePicker />
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction="column" alignItems="center" justify="center" component="main" style={{ flex: 1 }}>
            <Grid item>
              <Link to="/">
                <Logo className={classes.logo} />
              </Link>
            </Grid>
            <Grid item>
              <Typography variant="h3" component="h1" align="center">
                <Link to="/" className={classes.headerLink}>
                  HDTS Skating Analysis
                </Link>
              </Typography>
            </Grid>
            <Grid container item direction="column" alignItems="center" justify="center">
              {children}
            </Grid>
          </Grid>
        </Container>
        <Footer />
      </div>
    </>
  )
}

export default MainPage
