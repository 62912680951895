import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((t: Theme) => ({
  result: {
    padding: t.spacing(1),
  },
  resultImage: {
    width: '100%',
  },
  i: {
    fontStyle: 'italic',
  },
  imageContainer: {
    margin: 'auto',
  },
  ok: {
    color: t.palette.success.main,
  },
  nok: {
    color: t.palette.error.main,
  },
}))

export default useStyles
