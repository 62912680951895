import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import SaveIcon from '@material-ui/icons/Save'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'

import { changeNote, saveNote } from '../../store/application/actions'
import { GlobalState } from '../../store/configureStore'
import { LocationState, ParamTypes, Result as ResultType } from '../../types'
import { MAX_LENGTH } from '../../utils/consts'
import { useDebounce } from '../../utils/useDebounce'
import { useQuery } from '../../utils/useQuery'
import useStyles from './useStyles'

interface Props {
  result: ResultType
  a: number
  r: number
  editable: boolean
}

const Note = (props: Props) => {
  const { result, a, r, editable } = props
  const classes = useStyles()
  const location = useLocation<LocationState>()
  const dispatch = useDispatch()
  const [t] = useTranslation()
  const { id } = useParams<ParamTypes>()
  const { edit } = useQuery(location)

  const [note, setNote] = useState<string>(result.notes)
  const debouncedNote = useDebounce(note, 200)
  const [disabled, setDisabled] = useState<boolean>(true)

  const notes = useSelector((state: GlobalState) => state.application.newNotes)

  useEffect(() => {
    if (result.notes !== debouncedNote) {
      dispatch(changeNote({ a_idx: a, r_idx: r, content: debouncedNote }))
    }
  }, [a, debouncedNote, dispatch, r, result.notes])

  useEffect(
    () =>
      setDisabled(
        result.notes === debouncedNote ||
          note.length > MAX_LENGTH ||
          notes.findIndex((el) => el.a_idx === a && el.r_idx === r) === -1,
      ),
    [a, debouncedNote, note.length, notes, r, result.notes],
  )

  return editable ? (
    <>
      <TextField
        className={classes.noteTextArea}
        id={`note-${result.title}`}
        label={t('Notes')}
        multiline
        rows={6}
        variant="outlined"
        value={note}
        onChange={(event) => setNote(event.target.value)}
        helperText={`${note.length} / ${MAX_LENGTH}`}
        error={note.length > MAX_LENGTH}
      />
      <Button
        type="button"
        variant="contained"
        color="primary"
        startIcon={<SaveIcon />}
        disabled={disabled}
        onClick={() => {
          if (note.length <= MAX_LENGTH) {
            dispatch(saveNote(id, edit || '', a, r))
          }
        }}
      >
        {t('Save')}
      </Button>
    </>
  ) : (
    <>
      <Typography variant="body1">{t('Notes')}:</Typography>
      <Typography variant="body1" gutterBottom className={classes.i}>
        {note}
      </Typography>
    </>
  )
}

export default Note
