// eslint-disable-next-line import/prefer-default-export
export const getPdfFromResponse = (blob: Blob, filename = 'document.pdf') => {
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.style.display = 'none'
  a.href = url
  a.download = filename
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
  window.URL.revokeObjectURL(url)
}
