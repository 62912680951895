import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((t: Theme) => ({
  formControl: {
    margin: t.spacing(1),
  },
  selectEmpty: {
    marginTop: t.spacing(2),
  },
  option: {
    '& > span': {
      marginRight: 10,
    },
  },
}))

export default useStyles
