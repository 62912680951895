import { Typography } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { clearMessage } from '../../store/application/actions'
import { GlobalState } from '../../store/configureStore'
import useStyles from './useStyles'

const Footer = () => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const [t] = useTranslation()

  const message = useSelector((state: GlobalState) => state.application.message)

  useEffect(() => {
    if (message.text !== '') {
      // TODO resolve i18next warning and make sure the translations get to the dictionary
      enqueueSnackbar(t([`${message.text}`, 'NotifyUnspecific']), { variant: message.variant })
      dispatch(clearMessage())
    }
  }, [message, enqueueSnackbar, t, dispatch])

  return (
    <footer className={classes.footer}>
      <Typography variant="body1" align="center">
        Powered by{' '}
        <a className={classes.footerLink} href="https://www.hockeydts.com/">
          HDTS
        </a>{' '}
        and{' '}
        <a className={classes.footerLink} href="https://www.cognexa.com/">
          Cognexa
        </a>
      </Typography>
    </footer>
  )
}

export default Footer
