import './i18n'

import React from 'react'
import { CookiesProvider } from 'react-cookie'
import { render } from 'react-dom'
import { Provider } from 'react-redux'

import App from './App'
import configureStore from './store/configureStore'

const store = configureStore()

render(
  <CookiesProvider>
    <Provider store={store}>
      <App />
    </Provider>
  </CookiesProvider>,
  document.getElementById('root'),
)
