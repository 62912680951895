import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((t: Theme) => ({
  mainPage: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    background: t.palette.divider,
    backgroundColor: t.palette.background.paper,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  header: {
    padding: t.spacing(6, 0, 3),
  },
  logo: {
    width: '180px',
    cursor: 'pointer',
    marginTop: '-30px',
    [t.breakpoints.down('xs')]: {
      width: '150px',
      marginTop: t.spacing(2),
    },
  },
  headerLink: {
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
      color: t.palette.primary.light,
    },
  },
}))

export default useStyles
