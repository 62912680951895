import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((t: Theme) => ({
  input: {
    flex: 1,
    [t.breakpoints.down('xs')]: {
      flex: 'auto',
    },
  },
  button: {
    flex: '0 0 150px',
    marginLeft: t.spacing(1),
    [t.breakpoints.down('xs')]: {
      flex: 'auto',
      width: '100%',
      marginLeft: 0,
      marginTop: t.spacing(1),
    },
  },
}))

export default useStyles
