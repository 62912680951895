import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { ParamTypes } from '../../types'
import GoToReportForm from '../GoToReportForm/GoToReportForm'
import useStyles from './useStyles'

const WrongReport = () => {
  const [t] = useTranslation()
  const { id } = useParams<ParamTypes>()
  const classes = useStyles()

  return (
    <Grid container direction="column" alignItems="center">
      <Typography
        component="h1"
        variant="h4"
        align="center"
        color="textPrimary"
        gutterBottom
        className={classes.errorHeader}
      >
        {t('ReportNotFoundMessage', 'This report was not found!')}
      </Typography>
      <Typography variant="h5" align="center" color="textPrimary" gutterBottom className={classes.errorHeader}>
        {t('EnterValidReportID', 'Enter the valid report ID to view the full analysis.')}
      </Typography>
      <GoToReportForm value={id} />
    </Grid>
  )
}

export default WrongReport
