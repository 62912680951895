import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((t: Theme) => ({
  dd: {
    fontWeight: 700,
  },
  dt: {
    minWidth: '110px',
    marginLeft: t.spacing(2),
  },
  definition: {
    paddingTop: t.spacing(0),
    paddingBottom: t.spacing(0),
  },
}))

export default useStyles
