/* eslint-disable camelcase */
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { GlobalState } from '../../store/configureStore'
import { Hst } from '../../types'
import { DEFAULT_LANG } from '../../utils/consts'
import { getLocaleString } from '../../utils/dateTimeOptions'
import { formatSpeed, isMetricUnits } from '../../utils/formatNumbers'

interface Props {
  hst: Hst
  date: string
  training_center: string
  cycles: number | null
}

const ReportDetails = (props: Props) => {
  const { hst, date, training_center, cycles } = props
  const [t] = useTranslation()

  const language = useSelector((state: GlobalState) => state.application.language)

  return (
    <Grid item container direction="column">
      <Typography variant="h5" component="div" align="center">
        {t('PerfDate')}: <strong>{getLocaleString(new Date(date), language || DEFAULT_LANG)}</strong>
      </Typography>
      <Typography variant="h5" component="div" align="center">
        {t('Speed')}: <strong>{formatSpeed(hst.velocity, isMetricUnits(training_center))}</strong>
      </Typography>
      <Typography variant="h5" component="div" align="center">
        {t('Slope')}: <strong>{hst.angle}°</strong>
      </Typography>
      <Typography variant="h5" component="div" align="center">
        {t('PerfCycles')}: <strong>{cycles || '–'}</strong>
      </Typography>
    </Grid>
  )
}

export default ReportDetails
