import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import React, { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { GlobalState } from '../../store/configureStore'
import { Language } from '../../types'
import { DEFAULT_LANG } from '../../utils/consts'
import { countryToFlag } from '../../utils/countryToFlag'
import { languages } from '../../utils/languages'
import useStyles from './useStyles'

const LanguagePicker = () => {
  const classes = useStyles()
  const { i18n } = useTranslation()
  const [, setCookie] = useCookies()

  const language = useSelector((state: GlobalState) => state.application.language)
  const translation = useSelector((state: GlobalState) => state.application.translation)

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const lang = event.target.value as Language
    setCookie('lang', lang, { path: '/' })
  }

  useEffect(() => {
    i18n.addResourceBundle(language || DEFAULT_LANG, 'frontend', translation, true, true)
    i18n.changeLanguage(language || DEFAULT_LANG)
  }, [i18n, translation, language])

  return (
    <>
      <FormControl variant="outlined" fullWidth className={classes.formControl}>
        <Select
          labelId="language-select-label"
          id="language-select"
          value={language}
          onChange={handleChange}
          classes={{ select: classes.option }}
        >
          {languages.map((lang) => (
            <MenuItem value={lang.code} key={lang.code} classes={{ root: classes.option }}>
              <span>{countryToFlag(lang.isoCode)}</span>
              {lang.label} ({lang.isoCode})
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  )
}

export default LanguagePicker
