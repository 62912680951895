import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import React, { FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'

import { ParamTypes } from '../../types'
import useStyles from './useStyles'

const CompareWith = () => {
  const classes = useStyles()
  const [t] = useTranslation()
  const [idB, setIdB] = useState<string>('')
  const [uuid, setUuid] = useState<RegExpExecArray | null>(null)
  const history = useHistory()
  const { id } = useParams<ParamTypes>()

  useEffect(() => {
    setUuid(/[a-f\d]{8}-[a-f\d]{4}-4[a-f\d]{3}-[89ab][a-f\d]{3}-[a-f\d]{12}/.exec(idB))
  }, [idB])

  const openReport = () => {
    if (uuid) {
      history.push(`/comparison/${id}/${uuid[0]}`)
    }
  }

  return (
    <Grid
      container
      alignItems="center"
      component="form"
      onSubmit={(e: FormEvent) => {
        e.preventDefault()
        openReport()
      }}
    >
      <Grid item className={classes.input}>
        <TextField
          id="compare-with"
          label={t('CompareWith')}
          value={idB}
          variant="outlined"
          size="small"
          fullWidth
          onChange={(event) => setIdB(event.target.value)}
        />
      </Grid>
      <Grid item className={classes.button}>
        <Button variant="contained" color="primary" fullWidth disabled={!uuid} type="submit">
          {t('Compare')}
        </Button>
      </Grid>
    </Grid>
  )
}

export default CompareWith
