import { Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Slider from '@material-ui/core/Slider'
import { Pause, PlayArrow } from '@material-ui/icons'
import React, { useReducer, useRef, useState } from 'react'

import { Video } from '../../types'
import { getTimelineTime } from '../../utils/getTimelineTime'
import useStyles from './useStyles'

interface Props {
  videoA: Video
  videoB: Video
}

const ComparisonVideoPlayer = (props: Props) => {
  const { videoA, videoB } = props
  const classes = useStyles()

  const videoARef = useRef<HTMLVideoElement>(null)
  const videoBRef = useRef<HTMLVideoElement>(null)

  const [slider, setSlider] = useState<number>(0)

  const [isPlaying, toggle] = useReducer((t: boolean) => !t, false)

  const [maxDuration, setMaxDuration] = useState<number>(0)

  const play = () => {
    toggle()
    if (videoARef.current && videoBRef.current) {
      videoARef.current.play()
      videoBRef.current.play()
    }
  }

  const pause = () => {
    toggle()
    if (videoARef.current && videoBRef.current) {
      videoARef.current.pause()
      videoBRef.current.pause()
    }
  }

  const stop = () => {
    if (isPlaying) {
      toggle()
    }
    if (videoARef.current && videoBRef.current) {
      videoARef.current.pause()
      videoBRef.current.pause()
      videoARef.current.currentTime = 0
      videoBRef.current.currentTime = 0
    }
  }

  const eventTimeUpdate = (_e: React.SyntheticEvent<HTMLVideoElement, Event>) => {
    if (videoARef.current && videoBRef.current) {
      const nt = videoARef.current.currentTime * (100 / maxDuration)
      setSlider(nt)
    }
  }

  return (
    <>
      <Grid container item xs={12} md={6}>
        <video
          className={classes.video}
          poster={videoA.thumbnail}
          muted
          ref={videoARef}
          onEnded={(e: React.SyntheticEvent<HTMLVideoElement, Event>) => {
            if (e.currentTarget.duration === maxDuration) {
              stop()
            }
          }}
          onTimeUpdate={(e: React.SyntheticEvent<HTMLVideoElement, Event>) => eventTimeUpdate(e)}
          onLoadedMetadata={(e: React.SyntheticEvent<HTMLVideoElement, Event>) =>
            setMaxDuration(Math.max(maxDuration, e.currentTarget.duration))
          }
        >
          <source src={videoA.url} type="video/mp4" />
        </video>
      </Grid>
      <Grid container item sm={12} md={6}>
        <video
          className={classes.video}
          poster={videoB.thumbnail}
          muted
          ref={videoBRef}
          onEnded={(e: React.SyntheticEvent<HTMLVideoElement, Event>) => {
            if (e.currentTarget.duration === maxDuration) {
              stop()
            }
          }}
          onTimeUpdate={(e: React.SyntheticEvent<HTMLVideoElement, Event>) => eventTimeUpdate(e)}
          onLoadedMetadata={(e: React.SyntheticEvent<HTMLVideoElement, Event>) =>
            setMaxDuration(Math.max(maxDuration, e.currentTarget.duration))
          }
        >
          <source src={videoB.url} type="video/mp4" />
        </video>
      </Grid>
      <Grid container item xs={12} className={classes.controls}>
        <Grid container item xs={1} sm={1} md={1} className={classes.buttons}>
          <IconButton color="primary" onClick={() => (isPlaying ? pause() : play())}>
            {isPlaying ? <Pause /> : <PlayArrow />}
          </IconButton>
        </Grid>
        <Grid container item xs={2} sm={2} md={1} className={classes.buttons}>
          <Typography color="primary" className={classes.sliderTime}>
            {getTimelineTime(slider, maxDuration)}
          </Typography>
        </Grid>
        <Grid container item xs={9} sm={9} md={10} className={classes.sliderContainer}>
          <Slider
            className={classes.slider}
            aria-label="video timeline"
            value={slider}
            min={0}
            max={100}
            onChange={(_event: any, newValue: number | number[]) => {
              const nv = Array.isArray(newValue) ? newValue[0] : newValue
              const seekTo = maxDuration * (nv / 100)
              if (videoARef.current && videoBRef.current) {
                videoARef.current.currentTime = seekTo
                videoBRef.current.currentTime = seekTo
              }
              setSlider(nv)
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default ComparisonVideoPlayer
