import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((t: Theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    padding: t.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    margin: '1rem auto',
  },
  view: {
    marginTop: t.spacing(4),
    marginBottom: t.spacing(4),
  },
}))

export default useStyles
