import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  video: {
    display: 'block',
    margin: '0 auto',
    maxWidth: '100%',
  },
}))

export default useStyles
