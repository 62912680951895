/* eslint-disable camelcase */
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import React from 'react'

import useStyles from './useStyles'

interface Props {
  playerNameA: string
  playerNameB: string
  idA: number | null
  idB: number | null
  training_centerA: string
  training_centerB: string
}

const ComparisonPlayerNames = (props: Props) => {
  const { playerNameA, playerNameB, idA, idB, training_centerA, training_centerB } = props
  const classes = useStyles()

  return idA === idB && idA !== null ? (
    <Grid container item xs={12} direction="column">
      <Typography variant="h2" component="h2" align="center">
        {playerNameA}
      </Typography>
      <Typography variant="h6" component="div" gutterBottom align="center">
        {training_centerA}
      </Typography>
    </Grid>
  ) : (
    <>
      <Grid container item xs={6} direction="column">
        <Typography variant="h2" component="h2" align="center" className={classes.playerLeft}>
          {playerNameA}
        </Typography>
        <Typography variant="h6" component="div" gutterBottom align="center">
          {training_centerA}
        </Typography>
      </Grid>
      <Grid container item xs={6} direction="column">
        <Typography variant="h2" component="h2" align="center" className={classes.playerRight}>
          {playerNameB}
        </Typography>
        <Typography variant="h6" component="div" gutterBottom align="center">
          {training_centerB}
        </Typography>
      </Grid>
    </>
  )
}

export default ComparisonPlayerNames
