/* eslint-disable camelcase */
import Grid from '@material-ui/core/Grid'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { GlobalState } from '../../store/configureStore'
import { CountryCode, Player } from '../../types'
import { DEFAULT_LANG } from '../../utils/consts'
import { getLocaleString } from '../../utils/dateTimeOptions'
import { formatHeight, formatWeight, isMetricUnits } from '../../utils/formatNumbers'
import DefinitionItem from '../DefinitionItem/DefinitionItem'

interface Props {
  player: Player
  training_center: string
}

const PlayerDetails = (props: Props) => {
  const { player, training_center } = props
  const [t] = useTranslation()

  const language = useSelector((state: GlobalState) => state.application.language)

  return (
    <Grid item container component="dl">
      <Grid item container md={6} sm={12} alignContent="flex-start">
        <DefinitionItem
          label={t('PlayerBirthDate')}
          value={player.birthday && getLocaleString(new Date(player.birthday), language || DEFAULT_LANG)}
        />
        <DefinitionItem label={t('PlayerGender')} value={player.gender} />
        <DefinitionItem
          label={t('PlayerHeight')}
          value={player.height && formatHeight(player.height, isMetricUnits(training_center))}
        />
        <DefinitionItem
          label={t('PlayerWeight')}
          value={player.weight && formatWeight(player.weight, isMetricUnits(training_center))}
        />
      </Grid>
      <Grid item container md={6} sm={12} alignContent="flex-start">
        <DefinitionItem label={t('PlayerCountry')} value={player.country && CountryCode[player.country]} />
        <DefinitionItem label={t('PlayerStick')} value={player.stick} />
        <DefinitionItem label={t('PlayerPosition')} value={player.player_position} />
      </Grid>
    </Grid>
  )
}

export default PlayerDetails
