import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { SKATING_ANALYSIS } from '../../utils/consts'
import { ReactComponent as Logo } from '../../utils/Square44x44Logo.svg'
import LanguagePicker from '../LanguagePicker/LanguagePicker'
import useStyles from './useStyles'

interface Props {
  title: 'report' | 'comparison'
}

const Header = (props: Props) => {
  const { title } = props
  const classes = useStyles()
  const [t] = useTranslation()

  return (
    <Grid container item alignItems="center" justify="space-between" spacing={0} className={classes.header}>
      <Grid container item md={2} sm={8} xs={6} justify="flex-start">
        <Link to="/">
          <Logo className={classes.logo} />
        </Link>
      </Grid>
      <Grid container item md={8} xs={12} justify="center" className={classes.heading}>
        <Typography variant="h3" component="h1" align="center" className={classes.title}>
          {title === 'report' && t('SkatingAnalysisReport', { SKATING_ANALYSIS })}
          {title === 'comparison' && t('SkatingAnalysisComparison', { SKATING_ANALYSIS })}
        </Typography>
      </Grid>
      <Grid container item md={2} sm={4} xs={6} justify="flex-end" className={classes.languagePicker}>
        <LanguagePicker />
      </Grid>
    </Grid>
  )
}

export default Header
