import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((t: Theme) => ({
  footerButton: {
    margin: t.spacing(0.5, 1, 0.5, 0),
  },
  divider: {
    width: '90%',
    margin: 'auto',
    marginBottom: t.spacing(4),
  },
  copyReport: {
    '& input': {
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
  downloadWrapper: {
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -10,
    marginLeft: -10,
  },
}))

export default useStyles
