import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((t: Theme) => ({
  divider: {
    width: '100%',
    margin: 'auto',
    marginBottom: t.spacing(2),
    marginTop: t.spacing(2),
  },
}))

export default useStyles
