import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { InputData } from '../../types'
import ComparisonPlayerNames from '../ComparisonPlayerNames/ComparisonPlayerNames'
import ComparisonReportDetails from '../ComparisonReportDetails/ComparisonReportDetails'
import Header from '../Header/Header'
import PlayerDetails from '../PlayerDetails/PlayerDetails'
import StyledDivider from '../StyledDivider/StyledDivider'
import useStyles from './useStyles'

interface Props {
  dataA: InputData
  dataB: InputData
  cyclesA: number | null
  cyclesB: number | null
  idA: string
  idB: string
}

const ComparisonHeader = (props: Props) => {
  const { dataA, dataB, cyclesA, cyclesB, idA, idB } = props
  const [t] = useTranslation()
  const classes = useStyles()

  return (
    <Grid component="header" container item md={12} className={classes.header}>
      <Header title="comparison" />

      <Grid container item md={12} spacing={1}>
        <ComparisonPlayerNames
          playerNameA={dataA.player.name}
          playerNameB={dataB.player.name}
          idA={dataA.player.beesport_id}
          idB={dataB.player.beesport_id}
          training_centerA={dataA.training_center}
          training_centerB={dataB.training_center}
        />
        <Grid container item sm={6} md={6}>
          <ComparisonReportDetails
            id={idA}
            hst={dataA.hst}
            date={dataA.date}
            training_center={dataA.training_center}
            cycles={cyclesA}
          />
        </Grid>
        <Grid container item sm={6} md={6}>
          <ComparisonReportDetails
            id={idB}
            hst={dataB.hst}
            date={dataB.date}
            training_center={dataB.training_center}
            cycles={cyclesB}
          />
        </Grid>
      </Grid>

      <StyledDivider />

      <Grid container item md={12}>
        <Grid container item xs={12}>
          <Typography variant="h4" component="h2" gutterBottom>
            {t('PlayerDetailsTitle')}
          </Typography>
        </Grid>
        {dataA.player.beesport_id === dataB.player.beesport_id && dataA.player.beesport_id !== null ? (
          <>
            {new Date(dataA.date) > new Date(dataB.date) ? (
              <PlayerDetails training_center={dataA.training_center} player={dataA.player} />
            ) : (
              <PlayerDetails training_center={dataB.training_center} player={dataB.player} />
            )}
          </>
        ) : (
          <Grid container spacing={2}>
            <Grid container item sm={6}>
              <PlayerDetails player={dataA.player} training_center={dataA.training_center} />
            </Grid>
            <Grid container item sm={6}>
              <PlayerDetails player={dataB.player} training_center={dataB.training_center} />
            </Grid>
          </Grid>
        )}
      </Grid>

      <StyledDivider />
    </Grid>
  )
}

export default ComparisonHeader
