import { ThemeProvider } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'
import { SnackbarProvider } from 'notistack'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'

import Comparison from './pages/Comparison/Comparison'
import HomePage from './pages/HomePage/HomePage'
import MainPage from './pages/MainPage/MainPage'
import PageNotFound from './pages/PageNotFound/PageNotFound'
import Report from './pages/Report/Report'
import { theme } from './utils/theme'

const App = () => {
  const [t] = useTranslation()

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider>
        <Router>
          <Switch>
            <Route exact path="/report/:id">
              <Report />
            </Route>
            <Route exact path="/comparison/:idA/:idB">
              <Comparison />
            </Route>
            <Route exact path="/404">
              <MainPage title={t('PageNotFound')}>
                <PageNotFound />
              </MainPage>
            </Route>
            <Route exact path="/">
              <MainPage title={t('HomePage')}>
                <HomePage />
              </MainPage>
            </Route>
            <Route>
              <Redirect to="/404" />
            </Route>
          </Switch>
        </Router>
      </SnackbarProvider>
    </ThemeProvider>
  )
}
export default App
