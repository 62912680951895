import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((t: Theme) => ({
  notFound: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    background: t.palette.divider,
  },
  comment: {
    marginTop: t.spacing(2),
  },
}))

export default useStyles
