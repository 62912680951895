/* eslint-disable no-underscore-dangle */
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import reduxThunk from 'redux-thunk'

import applicationReducer from './application/reducer'
import { ApplicationState } from './application/types'

// eslint-disable-next-line import/prefer-default-export
export default function configureStore() {
  const rootReducer = combineReducers({
    application: applicationReducer,
  })
  return createStore(
    rootReducer,
    {},
    // @ts-ignore
    // eslint-disable-next-line no-underscore-dangle
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? compose(
          applyMiddleware(reduxThunk),
          // @ts-ignore
          // eslint-disable-next-line no-underscore-dangle
          window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true }),
        )
      : applyMiddleware(reduxThunk),
  )
}

export interface GlobalState {
  application: ApplicationState
}
