import axios from 'axios'
import { ThunkDispatch } from 'redux-thunk'

import { Language, Message, Note } from '../../types'
import { GlobalState } from '../configureStore'
import {
  ApplicationState,
  ApplicationTypes,
  CHANGE_NOTE,
  CLEAR_MESSAGE,
  DOWNLOAD_REPORT_ERRORED,
  DOWNLOAD_REPORT_FINISHED,
  DOWNLOAD_REPORT_STARTED,
  LOAD_REPORT_ERRORED,
  LOAD_REPORT_FINISHED,
  LOAD_REPORT_STARTED,
  LOAD_REPORTS_FOR_COMPARISON_ERRORED,
  LOAD_REPORTS_FOR_COMPARISON_FINISHED,
  LOAD_REPORTS_FOR_COMPARISON_STARTED,
  LOAD_TRANSLATION_ERRORED,
  LOAD_TRANSLATION_FINISHED,
  LOAD_TRANSLATION_STARTED,
  SAVE_NOTE_ERRORED,
  SAVE_NOTE_FINISHED,
  SAVE_NOTE_STARTED,
  SAVE_NOTES_ERRORED,
  SAVE_NOTES_FINISHED,
  SAVE_NOTES_STARTED,
  SEND_EMAIL_ERRORED,
  SEND_EMAIL_FINISHED,
  SEND_EMAIL_STARTED,
  SET_MESSAGE,
} from './types'

const getReport = (id: string, edit: string | null, queryLang: Language | null) => {
  return async (dispatch: ThunkDispatch<ApplicationState, null, ApplicationTypes>) => {
    dispatch({
      type: LOAD_REPORT_STARTED,
    })
    try {
      const loadReportRequest = await axios.get(`/api/jobs/${id}/report/?edit=${edit}&lang=${queryLang}`)
      dispatch({
        type: LOAD_REPORT_FINISHED,
        payload: {
          data: loadReportRequest.data,
        },
      })
    } catch {
      dispatch({
        type: LOAD_REPORT_ERRORED,
      })
    }
  }
}

const getReportsForComparison = (idA: string, idB: string, lang: Language | null) => {
  return async (dispatch: ThunkDispatch<ApplicationState, null, ApplicationTypes>) => {
    dispatch({
      type: LOAD_REPORTS_FOR_COMPARISON_STARTED,
    })
    try {
      const loadReportRequestA = await axios.get(`/api/jobs/${idA}/report/?lang=${lang}`)
      const langA = loadReportRequestA.data.lang
      const loadReportRequestB = await axios.get(`/api/jobs/${idB}/report/?lang=${langA}`)
      dispatch({
        type: LOAD_REPORTS_FOR_COMPARISON_FINISHED,
        payload: {
          comparison: { reportA: loadReportRequestA.data, reportB: loadReportRequestB.data },
        },
      })
    } catch {
      dispatch({
        type: LOAD_REPORTS_FOR_COMPARISON_ERRORED,
      })
    }
  }
}

const downloadReport = (id: string, lang: Language | null) => {
  return async (dispatch: ThunkDispatch<ApplicationState, null, ApplicationTypes>) => {
    dispatch({
      type: DOWNLOAD_REPORT_STARTED,
    })
    try {
      const downlaodReportRequest = await axios.get(`/api/pdf-generate/${id}/gen/?lang=${lang}`, {
        responseType: 'blob',
        timeout: 30000,
      })

      dispatch({
        type: DOWNLOAD_REPORT_FINISHED,
        payload: {
          blob: downlaodReportRequest.data,
        },
      })
    } catch {
      dispatch({
        type: DOWNLOAD_REPORT_ERRORED,
      })
    }
  }
}

const sendReportToEmail = (id: string, email: string, edit: string, lang: Language | null) => {
  return async (dispatch: ThunkDispatch<ApplicationState, null, ApplicationTypes>) => {
    dispatch({
      type: SEND_EMAIL_STARTED,
    })
    try {
      await axios.post(`/api/email-sender/?lang=${lang}`, { id, email, edit })
      dispatch({
        type: SEND_EMAIL_FINISHED,
      })
    } catch {
      dispatch({
        type: SEND_EMAIL_ERRORED,
      })
    }
  }
}

const saveNote = (id: string, edit: string, a: number, r: number) => {
  return async (dispatch: ThunkDispatch<ApplicationState, null, ApplicationTypes>, getState: () => GlobalState) => {
    const state = getState()
    dispatch({
      type: SAVE_NOTE_STARTED,
    })
    try {
      const note = state.application.newNotes.find((n) => n.a_idx === a && n.r_idx === r)
      if (note === undefined) {
        throw new Error('Note was not found')
      }
      const loadReportRequest = await axios.patch(`/api/jobs/${id}/report/notes/`, {
        edit,
        notes: [note],
      })
      dispatch({
        type: SAVE_NOTE_FINISHED,
        payload: {
          a,
          r,
          data: loadReportRequest.data,
        },
      })
    } catch {
      dispatch({
        type: SAVE_NOTE_ERRORED,
      })
    }
  }
}

const saveNotes = (id: string, edit: string) => {
  return async (dispatch: ThunkDispatch<ApplicationState, null, ApplicationTypes>, getState: () => GlobalState) => {
    const state = getState()
    dispatch({
      type: SAVE_NOTES_STARTED,
    })
    try {
      if (state.application.newNotes.length === 0) {
        throw new Error('No new notes')
      }
      const loadReportRequest = await axios.patch(`/api/jobs/${id}/report/notes/`, {
        edit,
        notes: state.application.newNotes,
      })
      dispatch({
        type: SAVE_NOTES_FINISHED,
        payload: { data: loadReportRequest.data },
      })
    } catch {
      dispatch({
        type: SAVE_NOTES_ERRORED,
      })
    }
  }
}

const changeNote = (note: Note) => {
  return async (dispatch: ThunkDispatch<ApplicationState, null, ApplicationTypes>) =>
    dispatch({
      type: CHANGE_NOTE,
      payload: {
        note,
      },
    })
}

const setMessage = (message: Message): ApplicationTypes => {
  return {
    type: SET_MESSAGE,
    payload: {
      message,
    },
  }
}

const clearMessage = (): ApplicationTypes => {
  return {
    type: CLEAR_MESSAGE,
  }
}

const getTranslation = (language: Language) => {
  return async (dispatch: ThunkDispatch<ApplicationState, null, ApplicationTypes>) => {
    dispatch({
      type: LOAD_TRANSLATION_STARTED,
    })
    try {
      const request = await axios.get(`/api/i18n/${language}/frontend/`)
      const translation = request.data.dict
      dispatch({
        type: LOAD_TRANSLATION_FINISHED,
        payload: { language, translation },
      })
    } catch {
      dispatch({
        type: LOAD_TRANSLATION_ERRORED,
      })
      dispatch(setMessage({ text: 'NotifyTranslationUnavailable', variant: 'error' }))
    }
  }
}

export {
  changeNote,
  clearMessage,
  downloadReport,
  getReport,
  getReportsForComparison,
  getTranslation,
  saveNote,
  saveNotes,
  sendReportToEmail,
  setMessage,
}
