import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import React from 'react'

import { View as ViewType } from '../../types'
import { isBetter } from '../../utils/isBetter'
import ComparisonResult from '../ComparisonResult/ComparisonResult'
import StyledDivider from '../StyledDivider/StyledDivider'
import useStyles from './useStyles'

interface Props {
  viewA: ViewType
  viewB: ViewType
  viewIdx: number
}

const ComparisonView = (props: Props) => {
  const { viewA, viewB, viewIdx } = props
  const classes = useStyles()

  const A = viewA.results.map((r, i) => {
    return (
      <ComparisonResult
        key={`${r.title}-A`}
        result={r}
        isBetter={isBetter(r.value, viewB.results[i].value, viewIdx, i)}
      />
    )
  })
  const B = viewB.results.map((r, i) => (
    <ComparisonResult
      key={`${r.title}-B`}
      result={r}
      isBetter={isBetter(r.value, viewA.results[i].value, viewIdx, i)}
    />
  ))

  return (
    <>
      <StyledDivider />
      <Grid container component="section">
        <Grid container item xs={12} component="header">
          <Typography variant="h4" component="h2" gutterBottom>
            {viewA.header}
          </Typography>
          {A.map((r, i) => (
            <Grid container component="article" className={classes.result} key={viewA.results[i].title}>
              <Grid item sm={12} component="header">
                <Typography variant="h5" gutterBottom>
                  {i + 1}. {viewA.results[i].title}
                </Typography>
              </Grid>
              {r}
              {B[i]}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  )
}

export default ComparisonView
