import { makeStyles } from '@material-ui/core/styles'

import { theme } from '../../utils/theme'

const useStyles = makeStyles((t) => ({
  video: {
    display: 'block',
    margin: '0 auto',
    maxWidth: '100%',
  },
  controls: {
    height: t.spacing(7),
    background: t.palette.grey[200],
  },
  buttons: {
    display: 'flex',
    alignContent: 'center',
  },
  sliderTime: {
    fontSize: '13px',
    fontFamily: 'Roboto',
  },
  sliderContainer: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
  },
  slider: {
    margin: t.spacing(1),
    width: '90%',
  },
}))

export default useStyles
