import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import React, { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom'

import ComparisonHeader from '../../components/ComparisonHeader/ComparisonHeader'
import ComparisonVideoPlayer from '../../components/ComparisonVideoPlayer/ComparisonVideoPlayer'
import ComparisonView from '../../components/ComparisonView/ComparisonView'
import Footer from '../../components/Footer/Footer'
import Loading from '../../components/Loading/Loading'
import ProcessingFailed from '../../components/ProcessingFailed/ProcessingFailed'
import StyledDivider from '../../components/StyledDivider/StyledDivider'
import { getReportsForComparison } from '../../store/application/actions'
import { GlobalState } from '../../store/configureStore'
import { LocationState, ParamTypesComparison } from '../../types'
import { useQuery } from '../../utils/useQuery'
import useStyles from './useStyles'

const Comparison = () => {
  const classes = useStyles()
  const location = useLocation<LocationState>()
  const history = useHistory()
  const dispatch = useDispatch()
  const [t] = useTranslation()
  const [cookies] = useCookies()
  const { lang: queryLang } = useQuery(location)

  const { idA, idB } = useParams<ParamTypesComparison>()
  const comparison = useSelector((state: GlobalState) => state.application.comparison)
  const isLoadingComparison = useSelector((state: GlobalState) => state.application.isLoadingComparison)
  const loadReportError = useSelector((state: GlobalState) => state.application.loadReportError)
  const language = useSelector((state: GlobalState) => state.application.language)

  const selectedLang = cookies.lang || queryLang

  useEffect(() => {
    dispatch(getReportsForComparison(idA, idB, selectedLang))
  }, [dispatch, idA, idB, selectedLang])

  useEffect(() => {
    if (language) {
      history.push({ search: `lang=${language}` })
    }
  }, [history, language])

  if (loadReportError !== null) {
    return <Redirect to="/404" />
  }

  if (isLoadingComparison || comparison.reportA === null) {
    return <Loading />
  }

  return (
    <>
      <Helmet>
        <title>{`SA | ${t('Comparison')}`}</title>
      </Helmet>
      <main className={classes.root}>
        <Container maxWidth="lg" component={Paper} className={classes.paper}>
          {comparison.reportA && comparison.reportB && (
            <ComparisonHeader
              dataA={comparison.reportA.input_data}
              dataB={comparison.reportB.input_data}
              cyclesA={comparison.reportA.report?.key_positions?.count || null}
              cyclesB={comparison.reportB.report?.key_positions?.count || null}
              idA={comparison.reportA.id}
              idB={comparison.reportB.id}
            />
          )}
          {comparison.reportA && comparison.reportA.report && comparison.reportB && comparison.reportB.report ? (
            <>
              <section>
                <Grid container item md={12}>
                  <Grid container item xs={12}>
                    <Typography variant="h4" component="h2" gutterBottom>
                      {t('VideoReport', 'Video report of the performance')}
                    </Typography>
                  </Grid>
                  <ComparisonVideoPlayer
                    videoA={comparison.reportA.report.video}
                    videoB={comparison.reportB.report.video}
                  />
                </Grid>
                {comparison.reportA.report.analyses.map((analyse, i) => (
                  <Grid container item md={12} className={classes.view} key={analyse.header}>
                    <ComparisonView viewIdx={i} viewA={analyse} viewB={comparison!.reportB!.report!.analyses[i]} />
                  </Grid>
                ))}
              </section>
            </>
          ) : (
            <ProcessingFailed message={t('OneReportFailed', 'One of the reports has failed during the processing')} />
          )}
          <StyledDivider />
        </Container>
      </main>
      <Footer />
    </>
  )
}

export default Comparison
