const isMetricUnits = (trainingCenter: string) => {
  if (trainingCenter.includes('demo') || trainingCenter.includes('test')) return false
  const match = trainingCenter.search(/\d/)
  const centerName = match > 0 ? trainingCenter.slice(0, match) : trainingCenter
  return !['detroit', 'sandiego'].includes(centerName)
}

const formatHeight = (value: number, useMetricUnits: boolean) => {
  if (useMetricUnits) {
    return `${value} cm`
  }
  const inches = Math.round(value * 0.393700787)
  return `${Math.floor(inches / 12)}ft ${inches % 12}in`
}

const formatWeight = (value: number, useMetricUnits: boolean) =>
  useMetricUnits ? `${value} kg` : `${(value * 2.205).toFixed(2)} lb`

// this is intentional
const formatSpeed = (value: number, useMetricUnits: boolean) => (useMetricUnits ? `${value} km/h` : `${value} mph`)

export { formatHeight, formatSpeed, formatWeight, isMetricUnits }
