import Typography from '@material-ui/core/Typography'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import useStyles from './useStyles'

const PageNotFound = () => {
  const classes = useStyles()
  const [t] = useTranslation()

  return (
    <>
      <Typography component="h1" variant="h4" align="center" color="textPrimary" gutterBottom>
        {t('PageNotFoundMessage', 'This page was not found')}
      </Typography>
      <Typography variant="h5" component="p" align="center" color="textPrimary" gutterBottom>
        <Link to="/" className={classes.link}>
          {t('GoToHomepage')}
        </Link>
      </Typography>
    </>
  )
}

export default PageNotFound
