import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((t: Theme) => ({
  result: {
    margin: t.spacing(4, 0),
  },
  resultImage: {
    width: '100%',
  },
  noteTextArea: {
    marginTop: t.spacing(2),
    marginBottom: t.spacing(2),
    width: '100%',
  },
  i: {
    fontStyle: 'italic',
  },
}))

export default useStyles
