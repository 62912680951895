/* eslint-disable camelcase */

export enum JobStatus {
  archived = 'archived',
  pending = 'pending',
  success = 'success',
}

export type Content = {
  value: number | null
  name: string
  unit: string | null
  deviation: number | null
  comment: string | null
}

export type Result = {
  title: string
  image: string
  value: number | null
  deviation: number | null
  unit: string | null
  notes: string
  metric: string
  comment_1: string | null
  comment_2: string | null
}

export type View = {
  header: string
  results: Result[]
}

export type Video = {
  url: string
  thumbnail: string
}

export type Report = {
  title: string
  video: Video
  analyses: View[]
  key_positions: KeyPositions
}

export type KeyPositions = {
  count: number
}

export type Player = {
  name: string
  stick: string | null
  gender: string | null
  height: number | null
  weight: number | null
  birthday: string | null
  player_position: string | null
  country: Country | null
  beesport_id: number | null
}

export type Hst = {
  angle: number
  velocity: number
}

export type InputData = {
  training_center: string
  date: string
  hst: Hst
  player: Player
}

export type Data = {
  id: string
  status: JobStatus
  report: Report | null
  input_data: InputData
  is_editable: boolean
  lang: Language
  dict: Object
}

export type Message = {
  text: string
  variant: 'error' | 'success'
}

export type Note = {
  a_idx: number
  r_idx: number
  content: string
}

export interface ParamTypes {
  id: string
}

export interface ParamTypesComparison {
  idA: string
  idB: string
}

export interface LocationState {
  pathname: string
  search: string
}

export enum CountryCode {
  ru = 'Russia',
  sk = 'Slovakia',
  de = 'Germany',
  hu = 'Hungary',
  cz = 'Czechia',
  at = 'Austria',
  uk = 'United Kingdom',
  ch = 'Switzerland',
  ca = 'Canada',
  us = 'United States of America',
  cn = 'China',
  be = 'Belgium',
  bg = 'Bulgaria',
  by = 'Belarus',
  dk = 'Denmark',
  ee = 'Estonia',
  fi = 'Finland',
  fr = 'France',
  hr = 'Croatia',
  it = 'Italy',
  jp = 'Japan',
  kr = 'South Korea',
  kz = 'Kazakhstan',
  lt = 'Lithuania',
  lv = 'Latvia',
  nl = 'Netherlands',
  no = 'Norway',
  pl = 'Poland',
  se = 'Sweden',
  si = 'Slovenia',
  ua = 'Ukraine',
}

export type Country =
  | 'ru'
  | 'sk'
  | 'de'
  | 'hu'
  | 'cz'
  | 'at'
  | 'uk'
  | 'ch'
  | 'ca'
  | 'us'
  | 'cn'
  | 'be'
  | 'bg'
  | 'by'
  | 'dk'
  | 'ee'
  | 'fi'
  | 'fr'
  | 'hr'
  | 'it'
  | 'jp'
  | 'kr'
  | 'kz'
  | 'lt'
  | 'lv'
  | 'nl'
  | 'no'
  | 'pl'
  | 'se'
  | 'si'
  | 'ua'

export type Language = 'en-US' | 'cs-CZ' | 'ru-RU' | 'test'
