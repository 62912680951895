import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import React from 'react'

import { View as ViewType } from '../../types'
import Result from '../Result/Result'
import StyledDivider from '../StyledDivider/StyledDivider'

interface Props {
  view: ViewType
  editable: boolean
  index: number
}

const View = (props: Props) => {
  const { view, editable, index } = props
  return (
    <>
      <StyledDivider />
      <Grid container component="section">
        <Grid container item xs={12} component="header">
          <Typography variant="h4" component="h2" gutterBottom>
            {view.header}
          </Typography>
        </Grid>
        {view.results.map((r, i) => (
          <Result key={r.title} result={r} a={index} r={i} editable={editable} />
        ))}
      </Grid>
    </>
  )
}

export default View
